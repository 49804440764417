<template>
    <li class="my-2">
        <Icon :v="link.icon" :set="link.iconSet || 'l'" />
        <span v-for="lang in link.lang || []" class="badge bg-light text-dark border">{{ lang }}</span>
        <a :href="link.url" target="_blank" rel="noopener">
            <LinkedText :text="link.headline" />
        </a>
        <LinkedText :text="link.extra || ''" />
        <p v-for="quote in link.quote ? Array.isArray(link.quote) ? link.quote : [link.quote] : []" class="small pl-4">
            <T>quotation.start</T><LinkedText :text="quote" /><T>quotation.end</T>
        </p>
        <blockquote v-if="link.response">
            <div v-if="Array.isArray(link.response)">
                <Icon v="comment-dots" />
                <LinkedText :text="link.response[0]" />
                <ul class="small">
                    <template v-for="(p, i) in link.response">
                        <li v-if="i !== 0" :key="i">
                            <LinkedText :text="p" />
                        </li>
                    </template>
                </ul>
            </div>
            <div v-else>
                <Icon v="comment-dots" />
                <LinkedText :text="link.response" />
            </div>
        </blockquote>
    </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import type { Link } from '~/locale/config.ts';

export default defineComponent({
    props: {
        link: { required: true, type: Object as PropType<Link> },
    },
});
</script>
